<script lang="ts" setup>
import constants from '~/constants'
import type { SocialNetworkMenuItem } from '~/types/footer'

const socialNetworkMenuItems: SocialNetworkMenuItem[] = [
  { href: 'https://vk.com/itabpro', iconName: 'social-networks-2/vk', name: 'VK' },
  { href: 'https://www.youtube.com/@itabpro1', iconName: 'social-networks-2/youtube', name: 'YouTube' },
  { href: 'https://www.tiktok.com/@itabpro', iconName: 'social-networks-2/tiktok', name: 'TikTok' },
  { href: 'https://t.me/itabpro', iconName: 'social-networks-2/telegram', name: 'Telegram' },
  { href: 'https://ok.ru/group/63163727478958', iconName: 'social-networks-2/odnoklassniki', name: 'Одноклассники' }
]
</script>

<template>
  <div class="flex flex-col md:my-10 md:flex-row-reverse md:justify-between">
    <div class="flex space-x-[15px]">
      <a
        v-for="{ href, iconName, name } in socialNetworkMenuItems"
        :key="href"
        :aria-label="name"
        class="mb-6 text-[44px] leading-[44px] text-main-green md:mb-0"
        :href="href"
        target="_blank"
      ><SvgIcon :name="iconName" /></a>
    </div>
    <div class="flex items-center justify-between rounded-xl bg-[#f6f6f6] p-3 md:justify-normal">
      <div class="mr-4 flex items-center md:mr-10">
        <SvgIcon class="mr-4 text-[32px]" name="podcasts/headphones" />
        <span>Подкасты о&nbsp;здоровье</span>
      </div>
      <a
        class="rounded-xl bg-white px-4 py-3 font-medium text-main-green hover:no-underline md:p-4"
        :href="constants.podcastsUrl"
        target="_blank"
      >Слушать</a>
    </div>
  </div>
</template>
