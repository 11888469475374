<script lang="ts" setup>
const title = 'iTAB'
</script>

<template>
  <NuxtLink class="logo block bg-[url('assets/img/logo.svg')] bg-contain bg-no-repeat indent-[-9999px]" to="/">
    {{ title }}
  </NuxtLink>
</template>

<style lang="scss" scoped>
.logo {
  $height: 32px;

  height: $height;
  width: calc(1176.92 * $height / 376.91735);
}
</style>
