<script lang="ts" setup>
import type { FetchError } from 'ofetch'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import BasicInput from '~/components/form-elements/BasicInput.vue'
import BasicTextarea from '~/components/form-elements/BasicTextarea.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import ApiErrorHandler from '~/helpers/api-error-handler'

const model = defineModel<boolean>()

const props = defineProps<{
  textPrefix: string
}>()

const messages = {
  productDescriptionFieldCannotBeEmpty: 'Описание товара не введено.',
  failedToSubmitProductDescription: 'Не удалось отправить описание товара.',
  productDescriptionIsSent: 'Описание товара отправлено.'
}

const { $apiHelper, $toast } = useNuxtApp()

const form = ref({ email: null, text: null })
const hasUnsavedChanges = ref(false)
const isSending = ref(false)

function close (): void {
  model.value = false
}

async function sendData (): Promise<void> {
  const { email, text } = form.value
  const { productDescriptionFieldCannotBeEmpty, failedToSubmitProductDescription, productDescriptionIsSent } = messages

  if (!text) {
    $toast.error(productDescriptionFieldCannotBeEmpty)

    return
  }

  const data = { email, text: `${props.textPrefix}\n\n${text}` }

  isSending.value = true

  try {
    await $apiHelper.messages.createMessage(data)

    $toast.success(productDescriptionIsSent)

    hasUnsavedChanges.value = false

    close()
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as FetchError, failedToSubmitProductDescription)
  }

  isSending.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" :has-unsaved-changes="hasUnsavedChanges" prevent-unsaved-changes>
    <template #title>
      Не&nbsp;нашли нужный товар?
    </template>

    <Notification class="mb-6" is-small status="info">
      Мы&nbsp;постараемся добавить его в&nbsp;ассортимент.
    </Notification>

    <form novalidate @change="hasUnsavedChanges = true" @submit.prevent>
      <BasicTextarea
        v-model="form.text"
        class="mb-6"
        is-required
        label-text="Описание товара"
        :max-length="4096"
      />

      <BasicInput
        v-model="form.email"
        class="mb-6"
        :description="'Предоставьте свой email, чтобы мы\xA0могли дать комментарий. Это необязательно.'"
        label-text="Email"
        :max-length="255"
      />
    </form>

    <BasicButton color="green" :is-loading="isSending" title="Отправить" @click="sendData" />
  </CustomModalWindow>
</template>
